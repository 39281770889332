<template>
    <div>
        <a-spin :spinning="loading">
            <a-form ref="form" name="form" :model="modelRef" @finish="onSubmit" :labelCol="{ span: 4 }" :wrapperCol="{span: 16}">

                <a-form-item label="商品海报通用背景图" extra="建议宽高比：4:6.9">
                    <a-upload :disabled="isSee" v-model:file-list="goodsFileList" name="file" list-type="picture-card"
                              class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
                              @change="(file) => handleChange(file, 1)"
							  @remove="onDel(1)"
							  >
                        <div v-if="goodsFileList.length < 1">
                            <Icon icon="PlusOutlined"></Icon>
                            <div class="ant-upload-text">上传</div>
                        </div>
                    </a-upload>
                </a-form-item>

                <a-form-item label="卖品海报通用背景图" extra="建议宽高比：4:6.9">
                    <a-upload :disabled="isSee" v-model:file-list="snackFileList" name="file" list-type="picture-card"
                              class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
                              @change="(file) => handleChange(file, 2)"
							  @remove="onDel(2)"
							  >
                        <div v-if="snackFileList.length < 1">
                            <Icon icon="PlusOutlined"></Icon>
                            <div class="ant-upload-text">上传</div>
                        </div>
                    </a-upload>
                </a-form-item>

                <a-form-item label="购票页海报通用背景图" extra="建议宽高比：5:6.7">
                    <a-upload :disabled="isSee" v-model:file-list="ticketFileList" name="file" list-type="picture-card"
                              class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
                              @change="(file) => handleChange(file, 3)"
							  @remove="onDel(3)"
							  >
                        <div v-if="ticketFileList.length < 1">
                            <Icon icon="PlusOutlined"></Icon>
                            <div class="ant-upload-text">上传</div>
                        </div>
                    </a-upload>
                </a-form-item>

                <a-form-item label="小程序链接分享图" extra="建议宽高比：5:4">
                    <a-upload :disabled="isSee" v-model:file-list="linkFileList" name="file" list-type="picture-card"
                              class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
                              @change="(file) => handleChange(file, 4)"
							  @remove="onDel(4)"
							  >
                        <div v-if="linkFileList.length < 1">
                            <Icon icon="PlusOutlined"></Icon>
                            <div class="ant-upload-text">上传</div>
                        </div>
                    </a-upload>
                </a-form-item>

                <a-row>
                    <a-col :offset="6" style="margin-top: 40px;">
                        <a-button v-permission="['operation_bill_common_submit']" type="primary" html-type="submit">提交</a-button>
                    </a-col>
                </a-row>
            </a-form>
        </a-spin>
    </div>
</template>

<script>
    import { Icon } from '@/components/icon/icon.js';
	import { getBillDetail, updateBill } from '@/service/modules/advert.js';
	export default {
	    components: {
	        Icon
	    },
		data() {
			return {
				loading: false,
				isSee: false,
				goodsFileList: [],
				snackFileList: [],
				ticketFileList: [],
				linkFileList: [],
				modelRef: {
					goods: '',
					snack: '',
					buyTicket: '',
					miniShare: ''
				}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData(){
				this.loading = true;
				try {
					let ret = await getBillDetail({});
					this.loading = false;
					if (ret.code === 200) {
						this.modelRef = ret.data;
						if (ret.data.goods) {
							this.goodsFileList = [{
								uid: 0,
								url: ret.data.goods
							}]
						}
						if (ret.data.snack) {
							this.snackFileList = [{
								uid: 0,
								url: ret.data.snack
							}]
						}
						if (ret.data.buyTicket) {
							this.ticketFileList = [{
								uid: 0,
								url: ret.data.buyTicket
							}]
						}
						if (ret.data.miniShare) {
							this.linkFileList = [{
								uid: 0,
								url: ret.data.miniShare
							}]
						}
						
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSubmit() {
				this.loading = true;
				try {
					let ret = await updateBill(this.modelRef);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('设置成功');
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onDel(type) {
				if (type === 1) {
					this.modelRef.goods = '';
				}
				if (type === 2) {
					this.modelRef.snack = '';
				}
				if (type === 3) {
					this.modelRef.buyTicket = '';
				}
				if (type === 4) {
					this.modelRef.miniShare = '';
				}
				return true;
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
			handleChange(info, type) {
				if (info.file.status === 'done') {
					// this.fileList = info.fileList;
					if (type === 1) {
						this.modelRef.goods = '';
					}
					if (type === 2) {
						this.modelRef.snack = '';
					}
					if (type === 3) {
						this.modelRef.buyTicket = '';
					}
					if (type === 4) {
						this.modelRef.miniShare = '';
					}
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							if (type === 1) {
								this.modelRef.goods = file.response.data.imgUrl;
							}
							if (type === 2) {
								this.modelRef.snack = file.response.data.imgUrl;
							}
							if (type === 3) {
                                this.modelRef.buyTicket = file.response.data.imgUrl;
                            }
                            if (type === 4) {
                                this.modelRef.miniShare = file.response.data.imgUrl;
                            }
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
		}
	}
</script>

<style scoped>
	.ui-text {
		color: red;
	}
</style>
